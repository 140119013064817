import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "basic-hooks"
    }}>{`Basic Hooks`}</h1>
    <h2 {...{
      "id": "rendering"
    }}>{`Rendering`}</h2>
    <p>{`Imagine we have a simple hook that we want to test:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useState, useCallback } from 'react'

export default function useCounter() {
  const [count, setCount] = useState(0)
  const increment = useCallback(() => setCount((x) => x + 1), [])
  return { count, increment }
}
`}</code></pre>
    <p>{`To test `}<inlineCode parentName="p">{`useCounter`}</inlineCode>{` we need to render it using the `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{` function provided by
`}<inlineCode parentName="p">{`react-hooks-testing-library`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook } from '@testing-library/react-hooks'
import useCounter from './useCounter'

test('should use counter', () => {
  const { result } = renderHook(() => useCounter())

  expect(result.current.count).toBe(0)
  expect(typeof result.current.increment).toBe('function')
})
`}</code></pre>
    <p>{`As you can see, the result's current value matches what is returned by our hook.`}</p>
    <h2 {...{
      "id": "updates"
    }}>{`Updates`}</h2>
    <p>{`The test shown above is great and all, but it doesn't actually test what we want to use the counter
for, i.e. counting. We can easily improve this test by calling the `}<inlineCode parentName="p">{`increment`}</inlineCode>{` function and checking
that the `}<inlineCode parentName="p">{`count`}</inlineCode>{` value increases:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks'
import useCounter from './useCounter'

test('should increment counter', () => {
  const { result } = renderHook(() => useCounter())

  act(() => {
    result.current.increment()
  })

  expect(result.current.count).toBe(1)
})
`}</code></pre>
    <p>{`After `}<inlineCode parentName="p">{`increment`}</inlineCode>{` is called, the current `}<inlineCode parentName="p">{`count`}</inlineCode>{` value now reflects the new value returned by our
hook.`}</p>
    <p>{`You may have also noticed that we also wrapped the `}<inlineCode parentName="p">{`increment`}</inlineCode>{` call in `}<inlineCode parentName="p">{`act`}</inlineCode>{`. This utility simulates
how our hook will act in a browser, allowing us to update the values within it. For more details on
`}<inlineCode parentName="p">{`act`}</inlineCode>{`, please see the `}<a parentName="p" {...{
        "href": "https://fb.me/react-wrap-tests-with-act"
      }}>{`React documentation`}</a>{`.`}</p>
    <p><strong parentName="p">{`NOTE`}</strong>{`: There's a gotcha with updates. `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{` mutates the value of `}<inlineCode parentName="p">{`current`}</inlineCode>{` when updates
happen so you cannot destructure its values as the assignment will make a copy locking into the
value at that time.`}</p>
    <h2 {...{
      "id": "providing-props"
    }}>{`Providing Props`}</h2>
    <p>{`Sometimes a hook relies on the props passed to it in order to do its thing. For example the
`}<inlineCode parentName="p">{`useCounter`}</inlineCode>{` hook could easily accept the initial value of the counter as a prop:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useState, useCallback } from 'react'

export default function useCounter(initialValue = 0) {
  const [count, setCount] = useState(initialValue)
  const increment = useCallback(() => setCount((x) => x + 1), [])
  return { count, increment }
}
`}</code></pre>
    <p>{`Setting the `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` prop in our test is as easy as calling the hook with the value we want to
use:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks'
import useCounter from './useCounter'

test('should increment counter from custom initial value', () => {
  const { result } = renderHook(() => useCounter(9000))

  act(() => {
    result.current.increment()
  })

  expect(result.current.count).toBe(9001)
})
`}</code></pre>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p>{`Many of the hook primitives use an array of dependent values to determine when to perform specific
actions, such as recalculating an expensive value or running an effect. If we extend our
`}<inlineCode parentName="p">{`useCounter`}</inlineCode>{` hook to have a `}<inlineCode parentName="p">{`reset`}</inlineCode>{` function that resets the value to the `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` it might
look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useState, useCallback } from 'react'

export default function useCounter(initialValue = 0) {
  const [count, setCount] = useState(initialValue)
  const increment = useCallback(() => setCount((x) => x + 1), [])
  const reset = useCallback(() => setCount(initialValue), [initialValue])
  return { count, increment, reset }
}
`}</code></pre>
    <p>{`Now, the only time the `}<inlineCode parentName="p">{`reset`}</inlineCode>{` function will be updated is if `}<inlineCode parentName="p">{`initialValue`}</inlineCode>{` changes. The most basic
way to handle changing the input props of our hook in a test is to simply update the value in a
variable and rerender the hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks'
import useCounter from './useCounter'

test('should reset counter to updated initial value', () => {
  let initialValue = 0
  const { result, rerender } = renderHook(() => useCounter(initialValue))

  initialValue = 10
  rerender()

  act(() => {
    result.current.reset()
  })

  expect(result.current.count).toBe(10)
})
`}</code></pre>
    <p>{`This is fine, but if there are lots of props, it can become a bit difficult to have variables to
keep track of them all. Another option is to use the `}<inlineCode parentName="p">{`initialProps`}</inlineCode>{` option and `}<inlineCode parentName="p">{`newProps`}</inlineCode>{` of
`}<inlineCode parentName="p">{`rerender`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks'
import useCounter from './useCounter'

test('should reset counter to updated initial value', () => {
  const { result, rerender } = renderHook(({ initialValue }) => useCounter(initialValue), {
    initialProps: { initialValue: 0 }
  })

  rerender({ initialValue: 10 })

  act(() => {
    result.current.reset()
  })

  expect(result.current.count).toBe(10)
})
`}</code></pre>
    <p>{`Another case where this is useful is when you want to limit the scope of the variables being closed
over to just be inside the hook callback. The following (contrived) example fails because the `}<inlineCode parentName="p">{`id`}</inlineCode>{`
value changes for both the setup and cleanup of the `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` call:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useEffect } from 'react'
import { renderHook } from '@testing-library/react-hooks'
import sideEffect from './sideEffect'

test('should clean up side effect', () => {
  let id = 'first'
  const { rerender } = renderHook(() => {
    useEffect(() => {
      sideEffect.start(id)
      return () => {
        sideEffect.stop(id) // this id will get the new value when the effect is cleaned up
      }
    }, [id])
  })

  id = 'second'
  rerender()

  expect(sideEffect.get('first')).toBe(false)
  expect(sideEffect.get('second')).toBe(true)
})
`}</code></pre>
    <p>{`By using the `}<inlineCode parentName="p">{`initialProps`}</inlineCode>{` and `}<inlineCode parentName="p">{`newProps`}</inlineCode>{` the captured `}<inlineCode parentName="p">{`id`}</inlineCode>{` value from the first render is used to
clean up the effect, allowing the test to pass as expected:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useEffect } from 'react'
import { renderHook } from '@testing-library/react-hooks'
import sideEffect from './sideEffect'

test('should clean up side effect', () => {
  const { rerender } = renderHook(
    ({ id }) => {
      useEffect(() => {
        sideEffect.start(id)
        return () => {
          sideEffect.stop(id) // this id will get the old value when the effect is cleaned up
        }
      }, [id])
    },
    {
      initialProps: { id: 'first' }
    }
  )

  rerender({ id: 'second' })

  expect(sideEffect.get('first')).toBe(false)
  expect(sideEffect.get('second')).toBe(true)
})
`}</code></pre>
    <p>{`This is a fairly obscure case, so pick the method that fits best for you and your test.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      